<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :estado-cliente="formattedItem.estadoCliente"
            :estado-sistema="formattedItem.estadoSistema"
          />
        </template>
      </b10-view-summary>
      <v-banner
        v-if="!networkOnline"
        color="warning"
        :icon="$vuetify.icons.values.warning"
      >
        No tienes conexión a internet, algunas opciones están desabilitadas.
      </v-banner>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './SistemaViewData'
import { nonEmpty } from '@/utils/templates'
import { TABLA, TFICHERO } from '@/utils/consts'
import { captureGeolocation, getLatLongURL } from '@/utils/maps'
import { get } from 'vuex-pathify'
import ExtraSubtitle from '../../components/SistemaExtraSubtitle'
import { encodeBase64 } from '@/utils/router'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        geolocalizar: {
          title: 'Capturar geolocalización actual',
          visible: true,
          icon: 'mapLocation',
        },
        irCliente: {
          title: 'Ir al cliente',
          visible: true,
        }
      },
      avisos: '',
      viewDetails: {
        ordenesTrabajo: {},
        planos: {},
        viasComunicacion: {},
        revisiones: {},
        personasContacto: {},
        serviciosContratados: {},
        ficheros: {},
        datoSistema: {},
        subsistemas: {},
      },
      clienteVisible: false
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    networkOnline: get('network/online'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.cliente.idcliente
        item.title = `${item.sistema.descripcion} (${item.sistema.codigo})`
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.cliente.nombre} (${item.cliente.idcliente})
          ${this.$offline.clienteDireccion.formatearDireccion(item.cliente_direccion)}
          ${item.cliente_direccion.observacion}
          Sistema de ${item.tsistema.descripcion}`)
        item.estadoCliente = item.cliente.estado
        item.estadoSistema = item.sistema.estado
        item.alerts = []
        if (this.avisos) {
          item.alerts.push({ value: this.avisos, type: 'warning' })
        }
        if (!item.cliente_direccion.latitud || !item.cliente_direccion.longitud) {
          item.alerts.push({
            value: 'Sistema sin geolocalización',
            type: 'warning',
            options: {
              persistent: true,
              actions: [{ text: 'Capturar', flat: true, handler: this.geolocalizar }],
            },
          })
        }
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  watch: {
    networkOnline (newValue) {
      for (const detail in this.viewDetails) {
        this.$set(this.viewDetails[detail], 'disabled', !newValue)
      }
      this.clienteVisible = (
        !!newValue && !!this.item?.dataset.cliente.idcliente && this.hasViewPerm(this.permissions.cliente.id)
      )
      this.toolbarOptions.irCliente.visible = this.clienteVisible
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.toolbarOptions.geolocalizar.visible = this.hasEditPerm(this.permissions.sistema.id)
      this.toolbarOptions.irCliente.visible = this.clienteVisible
      this.avisos = await this.$offline.clienteAviso.avisos(
        this.item.dataset.cliente.idcliente,
        this.item.dataset.sistema.idsistema,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial
      )
      this.title = `Sistema Nº${this.item.dataset.sistema.codigo}`
      // asignar moreInfoRows en loadPage() para tener accesibles los permisos del store (no disponibles en data())
      this.moreInfoRows = [
        { name: 'sistema.codigo', label: 'Código' },
        { name: 'sistema.nabonado1', label: 'Nº abonado' },
        { name: 'sistema.descripcion', label: 'Descripción' },
        { name: 'sistema.idcliente', label: 'Nº cliente' },
        { name: 'sistema.fconexion', label: 'Conexión', filter: this.$options.filters.shortDate },
        { name: 'direccion', label: 'Dirección' },
        { name: 'cliente_direccion.observacion', label: 'Observaciones' },
        { name: 'zona.descripcion', label: 'Zona' },
        { name: 'sistema.horario', label: 'Horario' },
        { name: 'sistema.grado_seguridad_descripcion', label: 'Grado' },
        { name: 'sistema.tactividad_descripcion', label: 'Sector' },
        { name: 'tsistema.descripcion', label: 'Tipo de sistema' },
        { name: 'sistema.central_alarma_nombre', label: 'Central de alarma' },
        { name: 'forma_pago.descripcion', label: 'Forma de pago', visible: this.hasViewPerm(this.permissions.clientes.verFormaPago) },
        { name: 'sistema.agente_nombre', label: 'Agente' },
        { name: 'sistema.comercial_nombre', label: 'Comercial' },
        { name: 'sistema.observaciones', label: 'Observaciones' },
      ]
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectSistema(this, this.routeParams.idsistema)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.toolbarOptions.map.visible = !!this.item.dataset.cliente_direccion.latitud && !!this.item.dataset.cliente_direccion.longitud
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      // plano
      this.viewDetails.planos = this.addDetail('plano', 'Planos', 'Plano del sistema', 'plano', !this.networkOnline)
      // contactos
      this.viewDetails.personasContacto = this.addDetail(
        'contactos', 'Personas de contacto', 'Personas de contacto del sistema', 'personaContacto', !this.networkOnline
      )
      // revisiones
      this.viewDetails.revisiones = this.addDetail(
        'revisiones', 'Revisiones', 'Revisiones programadas del sistema', 'revision', !this.networkOnline
      )
      // material
      if (this.hasViewPerm(this.permissions.materialSistema.id)) {
        const detailMaterialSistema = this.addDetail(
          'materialSistema', 'Material de sistema', 'Material instalado en el sistema', 'materialInstalar'
        )
        const datasetMaterialSistema = await Data.selectMaterialSistemaCount(this, this.routeParams.idsistema)
        detailMaterialSistema.badge = datasetMaterialSistema.count || 0
      }
      // órdenes de trabajo online
      this.viewDetails.ordenesTrabajo = this.addDetail(
        'ordenes', 'Órdenes de trabajo', 'Órdenes de trabajo del sistema', 'orden', !this.networkOnline
      )
      // servicios contratados
      this.viewDetails.serviciosContratados = this.addDetail(
        'serviciosContratados', 'Servicios contratados', 'Servicios contratados y cuotas de mantenimiento', 'servicioContratado', !this.networkOnline
      )
      // Vias de comunicación
      this.viewDetails.viasComunicacion = this.addDetail(
        'viasComunicacion', 'Vías de comunicación', 'Vías de comunicación del sistema', 'viaComunicacion', !this.networkOnline
      )
      // subsistemas
      this.viewDetails.subsistemas = this.addDetail(
        'subsistemas', 'Subsistemas', '', 'subsistema', !this.networkOnline
      )
      // ficheros
      if (this.hasViewPerm(this.permissions.sistemaAdjunto.id)) {
        this.viewDetails.ficheros = this.addDetail(
          'ficheros', 'Adjuntos', 'Adjuntos del sistema', 'attach', !this.networkOnline
        )
      }
      // datos
      if (this.hasViewPerm(this.permissions.sistemaDato.id)) {
        this.viewDetails.datoSistema = this.addDetail(
          'datos', 'Datos', 'Datos de sistema', 'datoPersonalizado', !this.networkOnline
        )
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'contactos') {
        this.$appRouter.push({
          name: 'telefonos__sistemas-cliente-telefono-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'revisiones') {
        this.$appRouter.push({
          name: 'sistemas__sistema-mant-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'subsistemas') {
        this.$appRouter.push({
          name: 'sistemas__subsis-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
          query: {
            extra: encodeBase64({ canClickListItem: false }),
          },
        })
      } else if (data.detail.name === 'ficheros') {
        this.$appRouter.push({
          name: 'ficheros__sistemas-fichero-list',
          params: {
            id: this.routeParams.idsistema,
            idtabla: TABLA.sistema.idtabla,
          },
        })
      } else if (data.detail.name === 'materialSistema') {
        this.$appRouter.push({
          name: 'offline__material-sistema-subsis-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'ordenes') {
        this.$appRouter.push({
          name: 'sistemas__ordenes-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'serviciosContratados') {
        this.$appRouter.push({
          name: 'sistemas__sistema-cuota-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'datos') {
        this.$appRouter.push({
          name: 'sistemas__dato-sistema-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'viasComunicacion') {
        this.$appRouter.push({
          name: 'sistemas__sistema-tviacomunicacion-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'plano') {
        this.$appRouter.push({
          name: 'ficheros__sistemas-fichero-list',
          params: {
            id: this.routeParams.idsistema,
            idtabla: TABLA.sistema.idtabla,
          },
          query: {
            _filter: encodeBase64({
              tipoFicheroClasificacion: {
                value: [TFICHERO.clasificacion.planos],
                options: { fixed: true },
              },
            })
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.map) {
        window.open(getLatLongURL(this.item.dataset.cliente_direccion.latitud, this.item.dataset.cliente_direccion.longitud), '_blank')
      } else if (option === this.toolbarOptions.geolocalizar) {
        this.geolocalizar()
      } else if (option === this.toolbarOptions.irCliente) {
        if (this.networkOnline) {
          this.$appRouter.push({
            name: 'clientes__cliente-view',
            params: {
              idcliente: this.item.dataset.cliente.idcliente,
            },
          })
        } else {
          this.$alert.showSnackbarError('Necesitas conexión a internet.')
        }
      }
    },
    async geolocalizar () {
      const geolocation = await captureGeolocation(this)
      if (!!geolocation?.lat && geolocation?.lng) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignar la geolocalización actual (${geolocation.lat}, ${geolocation.lng})
          a la dirección del sistema "${this.$offline.clienteDireccion.formatearDireccion(this.item.dataset.cliente_direccion)}"?`)
        if (res) {
          await Data.geolocalizar(
            this,
            this.item.dataset.cliente_direccion.idcliente_direccion,
            geolocation.lat,
            geolocation.lng
          )
          await this.loadItem()
        }
      }
    },
  },
}
</script>
